<template>
	<div class="homePage">
		<!-- 大屏header -->
		<div class="bigHeader">
			<div class="container-fluid headerLogo">
				<div class="container">
					<div class="headerLft">
						<img src="@/assets/logo.png" class="bigLogo">
						<h1>To be The Leader Of Silk Road</h1>
					</div>
					<div class="hearderRight">
						<div class="iconName">
							<span style="vertical-align: middle;" class="icon font_family icon-icon_user"></span>
							<span style="vertical-align: middle;">登录</span>
						</div>
						<div class="iconName language" @click.stop="seoClose = !seoClose">
							<span style="vertical-align: middle;">简体中文</span>
							<span style="vertical-align: middle;" class="icon font_family icon-icon_drop-down_sel" :class="{languageRotate: seoClose}"></span>
							<!-- 中英文切换 -->
							<ul class="seoChange" :style="{ height:seoClose?'80px':'0' }">
								<li @click.stop="seoChange(1)" :class="{seoChangeColor:seoChangeFlag == 1}">
									<span>简体中文</span>
									<span class="icon font_family icon-zhengque"></span>
								</li>
								<li @click.stop="seoChange(2)" :class="{seoChangeColor:seoChangeFlag == 2}">
									<span>English</span>
									<span class="icon font_family icon-zhengque"></span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="container">
				<ul class="naviLeft">
					<li :class="{chooseNav:urlFlag == 1}" @click="jumpUrl('')">
						<span style="vertical-align: middle;">首页</span>
						<span class="redHang"></span>
					</li>
					<li class="serItems" :class="{chooseNav:urlFlag == 2}"  @click="jumpUrl('serviceitems')" @mouseenter="enterServers($event)" @mouseleave="leaveServers($event)">
						<span style="vertical-align: middle;">服务项目</span>
						<span class="icon font_family icon-icon_drop-down_sel" style="vertical-align: middle;"></span>
						<span class="redHang"></span>
						<ul class="secondUl">
							<li @click.stop="jumpUrl('servicedetail')">国际货运代理</li>
							<li @click.stop="jumpUrl('serviceclearance')">进出口报关</li>
							<li @click.stop="jumpUrl('servicegoods')">仓储业务</li>
							<li @click.stop="jumpUrl('servicebonded')">跨境保税</li>
							<li @click.stop="jumpUrl('servicedeputy')">贸易代理</li>
							<li @click.stop="jumpUrl('servicepinkoi')">文创中心</li>
						</ul>
					</li>
					<li :class="{chooseNav:urlFlag == 3}" @click="jumpUrl('mileage')">
						<span style="vertical-align: middle;">丝路里程</span> 
						<span class="redHang"></span>
					</li>
					<li :class="{chooseNav:urlFlag == 4}" @click="jumpUrl('newscenter')">
						<span style="vertical-align: middle;">新闻中心</span> 
						<span class="redHang"></span>
					</li>
					<li :class="{chooseNav:urlFlag == 5}" @click="jumpUrl('team')">
						<span style="vertical-align: middle;">丝路团队</span> 
						<span class="redHang"></span>
					</li>
					<li :class="{chooseNav:urlFlag == 6}" @click="jumpUrl('joinus')">
						<span style="vertical-align: middle;">加入我们</span> 
						<span class="redHang"></span>
					</li>
				</ul>
				<div class="naviRight">
					<input type="text" v-model="inputValue" placeholder="输入搜索内容">
					<div class="icon font_family icon-icon_search"></div>
				</div>
			</div>
		</div>
		<!-- 小屏header -->
		<div class="smallHeader" :style="{top:naviTop}">
			<div class="container">
				<div class="headerLft headerLft2">
					<img src="@/assets/logo.png" class="bigLogo bigLogo2">
					<h1>To be The Leader Of Silk Road</h1>
					<ul class="naviLeft">
						<li :class="{chooseNav:urlFlag == 1}" @click="jumpUrl('')">
							<span style="vertical-align: middle;">首页</span>
							<span class="redHang"></span>
						</li>
						<li class="serItems" :class="{chooseNav:urlFlag == 2}" @click="jumpUrl('serviceitems')">
							<span style="vertical-align: middle;">服务项目</span>
							<span class="icon font_family icon-icon_drop-down_sel" style="vertical-align: middle;"></span>
							<span class="redHang"></span>
							<ul class="secondUl">
								<li @click.stop="jumpUrl('servicedetail')">国际货运代理</li>
								<li @click.stop="jumpUrl('serviceclearance')">进出口报关</li>
								<li @click.stop="jumpUrl('servicegoods')">仓储业务</li>
								<li @click.stop="jumpUrl('servicebonded')">跨境保税</li>
								<li @click.stop="jumpUrl('servicedeputy')">贸易代理</li>
								<li @click.stop="jumpUrl('servicepinkoi')">文创中心</li>
							</ul>
						</li>
						<li :class="{chooseNav:urlFlag == 3}" @click="jumpUrl('mileage')">
							<span style="vertical-align: middle;">丝路里程</span>
							<span class="redHang"></span>
						</li>
						<li :class="{chooseNav:urlFlag == 4}" @click="jumpUrl('newscenter')">
							<span style="vertical-align: middle;">新闻中心</span>
							<span class="redHang"></span>
						</li>
						<li :class="{chooseNav:urlFlag == 5}" @click="jumpUrl('team')">
							<span style="vertical-align: middle;">丝路团队</span>
							<span class="redHang"></span>
						</li>
						<li :class="{chooseNav:urlFlag == 6}" @click="jumpUrl('joinus')">
							<span style="vertical-align: middle;">加入我们</span>
							<span class="redHang"></span>
						</li>
					</ul>
				</div>
				<div class="hearderRight">
					<div class="iconName" @click="smallMenu = false, submenuFlag = false" v-show="smallMenu">
						<span class="icon font_family icon-guanbi"></span>
					</div>
					<div class="iconName moreNave" @click.stop="smallMenu = true"  v-show="!smallMenu">
						<span class="icon font_family icon-icon_more"></span>
					</div>
					<div class="iconName">
						<span class="icon font_family icon-icon_user" style="vertical-align: middle;"></span>
						<span class="writtenWords" style="vertical-align: middle;">登录</span>
					</div>
					<div class="iconName language writtenWords" @click.stop="seoClose = !seoClose">
						<span style="vertical-align: middle;">简体中文</span>
						<span class="icon font_family icon-icon_drop-down_sel" :class="{languageRotate: seoClose}" style="vertical-align: middle;"></span>
						<!-- 中英文切换 -->
						<ul class="seoChange" :style="{ height:seoClose?'80px':'0' }">
							<li @click.stop="seoChange(1)" :class="{seoChangeColor:seoChangeFlag == 1}">
								<span>简体中文</span>
								<span class="icon font_family icon-zhengque"></span>
							</li>
							<li @click.stop="seoChange(2)" :class="{seoChangeColor:seoChangeFlag == 2}">
								<span>English</span>
								<span class="icon font_family icon-zhengque"></span>
							</li>
						</ul>
					</div>
					<div class="iconName iconsearch" @click.stop="searchShow()">
						<span class="icon font_family icon-icon_search"></span>
					</div>
					<!-- 输入框展现 -->
					<div class="searchShow" v-show="bigSearchFlag">
						<input type="text" v-model="inputValue" :style="{width:bigSearchWidth}">
						<div class="icon font_family icon-icon_search" @click="closeSearch()"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 小屏菜单栏 -->
		<div class="smallMenu" :style="{left: smallMenu ? '0' : '100%'}">
			<!-- 首级菜单 -->
			<ul class="menuList">
				<li @click="secondShow('language')">
					<span>简体中文</span>
					<span class="icon font_family icon-icon_enter"></span>
				</li>
				<li @click.stop="jumpUrl('')">
					<span>首页</span>
					<span class="icon font_family icon-icon_enter"></span>
				</li>
				<li @click.stop="secondShow('service')">
					<span>服务项目</span>
					<span class="icon font_family icon-icon_enter"></span>
				</li>
				<li @click="jumpUrl('mileage')">
					<span>丝路里程</span>
					<span class="icon font_family icon-icon_enter"></span>
				</li>
				<li @click.stop="jumpUrl('newscenter')">
					<span>新闻中心</span>
					<span class="icon font_family icon-icon_enter"></span>
				</li>
				<li @click.stop="jumpUrl('team')">
					<span>丝路团队</span>
					<span class="icon font_family icon-icon_enter"></span>
				</li>
				<li @click="jumpUrl('joinus')">
					<span>加入我们</span>
					<span class="icon font_family icon-icon_enter"></span>
				</li>
			</ul>
			<!-- 服务项目菜单 -->
			<ul class="menuList menuList2" :style="{left:submenuFlag ? '0' : '100%'}">
				<div @click="submenuFlag = false" class="menuBack">
					<span class="icon font_family icon-icon_back"></span>
					返回
				</div>
				<li style="padding: 0 60px" @click.stop="jumpUrl(secondData.url)">{{ secondData.title }}</li>
				<li v-for="(val,index) of secondData.list" :key="index" @click.stop="jumpUrl(val.url)">
					<span>{{ val.name }}</span>
					<span class="icon font_family icon-icon_enter"></span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "homePage",
	props: ["urlFlag"],
	data() {
		return {
			seoClose: false, // 语言弹框
			seoChangeFlag: 1, // 语言切换
			inputValue: '', // 输入的值
			bigSearchFlag: false, // 搜索弹框
			bigSearchWidth: 0, // 搜索的宽度变化
			naviTop: '-130px', // 小的header离头部的距离
			submenuFlag: false, // 二级菜单弹出
			smallMenu: false, // 小屏弹出
			secondFrom: {
				language: {
					title: '简体中文',
					url: 'language',
					list: [
						{
							name: '简体中文',
							url: ''
						}, {
							name: 'English',
							url: ''
						}
					]
				},
				service: {
					title: '服务项目',
					url: 'serviceitems',
					list: [
						{
							name: '国际货运代理',
							url: 'servicedetail',
							id: ''
						}, {
							name: '进出口报关',
							url: 'servicedetail',
							id: ''
						}, {
							name: '仓储业务',
							url: 'servicedetail',
							id: ''
						}, {
							name: '跨境保税',
							url: 'servicedetail',
							id: ''
						}, {
							name: '贸易代理',
							url: 'servicedetail',
							id: ''
						}, {
							name: '文创中心',
							url: 'servicedetail',
							id: ''
						}
					]
				}

			},
			secondData: {}
		}
	},
	methods: {
		enterServers(e) {
			// console.log(e.currentTarget)
			e.currentTarget.childNodes[1].classList.add('languageRotate')
		},
		leaveServers(e) {
			e.currentTarget.childNodes[1].classList.remove('languageRotate')
		},
		// 路由跳转
		jumpUrl(url) {
			if (url != 'language') {
				this.bigSearchFlag = false
				this.$router.push('/' + url)
			}
		},
		// 二级菜单显示
		secondShow(n) {
			this.secondData = this.secondFrom[n]
			this.submenuFlag = true
		},
		// 关闭搜索弹框
		closeSearch() {
			if (!this.inputValue) {
				this.bigSearchWidth = '0'
				setTimeout(() => {
					this.bigSearchFlag = false
				}, 500)
			}
		},
		// 大屏搜索框显示
		searchShow() {
			this.bigSearchFlag = true
			setTimeout(() => {
				this.bigSearchWidth = '100%'
			}, 200)
		},
		// 切换语言
		seoChange(n) {
			this.seoChangeFlag = n
			this.seoClose = false
		},
		handleScroll() {
			//获取滚动时的高度
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			if (document.body.offsetWidth > 992) {
				if (scrollTop > 130) {
					this.naviTop = '0'
				} else {
					this.naviTop = '-130px'
				}
			} else {
				this.naviTop = '0'
			}
		},
		// 点击别的地方隐藏弹框
		closeAll() {
			this.seoClose = false
			this.bigSearchWidth = '0'
			setTimeout(() => {
				this.bigSearchFlag = false
			}, 500)
		}
	},
	mounted() {
		if (document.body.offsetWidth < 992) {
			this.naviTop = '0'
		}
		// 滚动条
		window.addEventListener("scroll",this.handleScroll)
		this.handleScroll()
		window.onresize = () => {
			this.handleScroll()
		}
		this.$nextTick(() => {
			document.querySelector('body').addEventListener('click', this.closeAll);
		})
	},
	onBeforeUnmount() {
		document.removeEventListener('scroll', this.handleScroll)
		document.querySelector('body').removeEventListener('click', this.handleBodyClick);
    }
};
</script>

<style scoped>
@import "homePage.css";
</style>
