<template>
    <div class="afterbody">
        <div class="container-fluid">
			<div class="container">
				<div class="row">
					<div class="col-sm-4 col-md-4">
						<div class="logoName icon font_family"><img src="../../assets/logo_white_small.png" style="width:60%"/></div>
						<div class="redBox"></div>
						<p class="footerBref">四川丝路里物流科技有限公司是第一批蓉欧班列的订舱货运代理，我们立志成为综合运输和物流服务的领军者之一。</p>
						<ul class="information information2">
							<li>
								<span class="icon font_family icon-icon-mail_white"></span>
								<span>srl-market@srleader.com</span>
								<span>sales@railtec-log.com</span>
							</li>
							<li>
								<span class="icon font_family icon-icon_phone_white"></span>
								<span>Fisher YU 18602865214</span>
								<span>Maggie 17360190854</span>
							</li>
							<li>
								<span class="icon font_family icon-icon_location"></span>
								<span>中国（四川）自由贸易试验区成都高新区交子大道177号A座1506-1508</span>
							</li>
						</ul>
						<div>
							<p class="Copyright">Copyright 2021 www.srleader.com All Rights Reserved. <br/>
								四川丝路里物流科技有限公司 版权所有 <br/>
							</p>
						</div>
						<div style="width:27em; margin:0; display: flex; justify-content: space-between; flex-flow: row nowrap; align-items: center;">
							<a style="display:block; text-decoration:none; height:20px;line-height:20px; font-size: 12px" target="_blank" href="https://beian.miit.gov.cn/">
								<p style="line-height:20px; color:#fff; font-size: 14px">蜀ICP备17029874号-2</p>
							</a>
							<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002003792" style="display:block; text-decoration:none; height:20px;line-height:20px;">
							 <img src="../../assets/beian_impic.png" style="float:left; box-sizing: border-box; width: 16px; height: 16px"/>
							 <p style="float:left; height:20px; line-height:20px; color:#fff; font-size: 14px">川公网安备 51019002003792号</p>
							</a>
    					</div>


					</div>
					<div class="col-sm-5 col-md-5 footerInfor">
						<div class="aboutAs">联系我们</div>
						<div class="redBox"></div>
						<ul class="information">
							<li>
								<span class="icon font_family icon-icon-mail_white"></span>
								<span>srl-market@srleader.com</span>
								<span>sales@railtec-log.com</span>
							</li>
							<li>
								<span class="icon font_family icon-icon_phone_white"></span>
								<span>Fisher YU 18602865214</span>
								<span>Maggie 17360190854</span>
							</li>
							<li>
								<span class="icon font_family icon-icon_location"></span>
								<span>中国（四川）自由贸易试验区成都高新区交子大道<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;177号A座1506-1508</span>
							</li>
						</ul>
					</div>
					<div class="col-sm-3 col-md-3 footerInfor">
						<div class="aboutAs">快速导航入口</div>
						<div class="redBox"></div>
						<ul class="nemuList">
							<li @click="jumpUrl('')">
								网站首页
								<span>Home</span>
							</li>
							<li @click="jumpUrl('serviceitems')">
								服务项目
								<span>Our Service</span>
							</li>
							<li @click="jumpUrl('mileage')">
								丝路里程
								<span>Calendar</span>
							</li>
							<li @click="jumpUrl('newscenter')">
								新闻中心
								<span>News Center</span>
							</li>
							<li @click="jumpUrl('team')">
								丝路团队
								<span>SRL Leadership</span>
							</li>
							<li @click="jumpUrl('joinus')">
								加入我们
								<span>Join Us</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    name: "afterbody",
	components: {
	},
	methods: {
		// 路由跳转
		jumpUrl(url) {
			this.$router.push('/' + url)
		},
	},
	mounted() {
	},
}
</script>

<style>
@import "afterbody.css";
/* * {
	display: flex;
	align-items: center;
} */
</style>